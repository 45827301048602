import React from "react";

import NavigationItem from "./Navigationitem/Navigationitem";

import classes from "./Navigationitems.module.css";

const NavigationItems = (props) => {
  return (
    <ul className={classes.NavigationItems}>
      <NavigationItem link="/">Időjárás</NavigationItem>
      <NavigationItem link="/rolunk">Rólunk</NavigationItem>
    </ul>
  );
};

export default NavigationItems;
