import React from "react";

import Btn from "../Btn/Btn";

import classes from "./Input.module.css";

const input = (props) => (
  <div className={classes.Input}>
    <input
      ref={props.referance}
      type={props.type}
      placeholder={props.children}
      value={props.value}
      onChange={props.onChange}
      onKeyDown={props.onKeyDown}
    />
    <Btn onClick={props.onClick} icon={props.icon} />
  </div>
);

export default input;
