import React from "react";

import classes from "./SubWeatherReport.module.css"

const subWeatherReport = (props) => (
  <div className={classes.SubWeatherReport}>
    <div className={classes.icon}>
      {props.icon}
    </div>
    <div className={classes.data}>
      <p>{props.children}</p>
      <p className={classes.gray}>{props.dataPoint}</p>
    </div>
  </div>
)

export default subWeatherReport;