import React from "react";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import UmbrellaIcon from "@mui/icons-material/Umbrella";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import AirIcon from "@mui/icons-material/Air";

import classes from "./TwentyFourHTable.module.css";

const twentyFourHTable = (props) => {
  return (
    <div className={classes.TwentyFourHTable}>
      <div className={classes.TwentyFourHTableIcons}>
        <AccessTimeIcon />
        <CloudQueueIcon />
        <ThermostatIcon />
        <UmbrellaIcon />
        <AvTimerIcon />
        <AirIcon />
      </div>
      <div className={classes.table}>
        <table>
          <tbody>
            {props.children}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default twentyFourHTable;
