import React from "react";

import { MapContainer, TileLayer, Marker } from "react-leaflet";
import ChangeView from "./ChangeView/ChangeView";

import classes from "./Map.module.css";

const map = (props) => {
  return (
    <div id="map">
      <MapContainer
        className={classes.Map}
        center={props.center}
        zoom={10}
        scrollWheelZoom={false}
      >
        <ChangeView center={props.center} zoom={10} />
        <TileLayer
          attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {/* <ImageOverlay
        url="http://www.lib.utexas.edu/maps/historical/newark_nj_1922.jpg"
        bounds={[[47, 19], [48, 20]]}
        opacity={1}
        zIndex={10}
      /> */}
        <Marker position={props.coordinates}></Marker>
      </MapContainer>
    </div>
  );
};

export default map;
