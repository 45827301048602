import React from "react";

import classes from "./AboutPage.module.css";

const about = (props) => (
  <div id="about" className={classes.AboutPage}>
    <h3>Üdvözlünk!</h3>
    <h4>Tervezz előre a Lesz kapás? oldallal</h4>
    <p>
      Ezt a weblapot azért hoztuk létre, hogy megkönnyítse horgásztársaink
      életét azzal, hogy egy helyen, gyorsan és hatékonyan megtaláljanak minden
      olyan időjárással kapcsolatos információt, ami egy sikeres horgászat
      megtervezéséhez szükséges lehet.
    </p>
    <p>
      Egyéni tapasztalataink, valamint számos cikk és tanulmány elolvasása után
      arra a következtetésre jutottunk, hogy a halak táplálkozását (megannyi más
      tényező mellett) 3 fő változó befolyásolja:
    </p>
    <ul>
      <li>légnyomás</li>
      <li>holdjárás</li>
      <li>holdtölte</li>
    </ul>
    <p>
      Ezeket az adatokat gyűjtöttük össze úgy egy felületre, hogy azok könnyedén
      befogadhatók és értelmezhetők legyenek. Keress rá a kívánt településre, és
      tervezd meg a hétvégi pecát, vagy nézd meg, milyen fogási eredményeket hoz
      a délután!
    </p>
    <h4>Olvasnivaló a tópartra</h4>
    <p>Válogatás az általunk érdekesnek talált cikkekből:</p>
    <ul>
      <li>
        Lajos Tibor -{" "}
        <a
          href="https://www.facebook.com/notes/lajos-tibor/mikor-%C3%A9rdemes-horg%C3%A1szni/1279143678852211/"
          rel="noreferrer"
          target={"_blank"}
        >
          Mikor érdemes horgászni?
        </a>{" "}
      </li>
      <li>
        Jávorka Dániel -{" "}
        <a
          href="https://www.haldorado.hu/topikok/horgasziskola-kezdoknek-c10/pontysuli-c24/az-abiotikus-kornyezeti-tenyezok-hatasai-a-pontyok-taplalkozasara-1-a-homerseklet-a-legnyomas-es-a-frontok-a2722"
          rel="noreferrer"
          target={"_blank"}
        >
          Az abiotikus környezeti tényezők hatásai a pontyok táplálkozására
        </a>{" "}
      </li>
      <li>
        Dobó Ferenc -{" "}
        <a
          href="https://horgaszat.hu/a-ponty-etvagyaval-kapcsolatos-tudnivalok-dobo-ferenc-1931/"
          rel="noreferrer"
          target={"_blank"}
        >
          A ponty étvágyával kapcsolatos tudnivalók
        </a>{" "}
      </li>
      <li>
        Joe Bucher -{" "}
        <a
          href="https://www.solunar.com/the_real_scoop.aspx"
          rel="noreferrer"
          target={"_blank"}
        >
          The Real Scoop
        </a>{" "}
      </li>
    </ul>
    <h4>Ismerd meg időjósunkat és asztronómusunkat</h4>
    <p>
      Meterológiai adatainkat a londoni székhelyű{" "}
      <a href="https://openweathermap.org/" rel="noreferrer" target={"_blank"}>
        OpenWeatherMap
      </a>{" "}
      biztosítja. A szolunáris jelentések{" "}
      <a
        href="https://github.com/mourner/suncalc/blob/master/README.md"
        rel="noreferrer"
        target={"_blank"}
      >
        Vladimir Agafonkin
      </a>{" "}
      jóvoltából kerülnek hozzánk felhasználásra.
    </p>
  </div>
);
export default about;
