import React, { Component } from "react";

import Aux from "../Aux/Aux";
import Toolbar from "../../components/Navigation/Toolbar/Toolbar";
import classes from "./Layout.module.css";
import SideMenu from "../../components/Navigation/Sidemenu/Sidemenu";
import Menubtn from "../UI/Menubtn/Menubtn";

class Layout extends Component {
  state = {
    showSideMenu: false,
    menuBtnOpen: true,
  };

  sideMenuClosedHandler = () => {
    this.setState({ showSideMenu: false, menuBtnOpen: true });
  };

  openSideMenuHandler = () => {
    this.setState((prevState) => {
      return {
        showSideMenu: !prevState.showSideMenu,
        menuBtnOpen: !prevState.menuBtnOpen,
      };
    });
  };

  render() {
    return (
      <Aux>
        <Menubtn clicked={this.openSideMenuHandler} opened={this.state.menuBtnOpen}/>
        <Toolbar
          openSideMenuHandler={this.openSideMenuHandler}
          open={this.state.menuBtnOpen}
        />
        <SideMenu
          open={this.state.showSideMenu}
          closed={this.sideMenuClosedHandler}
        />
        <main className={classes.Content}>{this.props.children}</main>
      </Aux>
    );
  }
}

export default Layout;
