import React from "react";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import classes from "./Menubtn.module.css";

const menuBtn = (props) =>
  props.opened ? (
    <div className={classes.Menubtn} onClick={props.clicked}>
      <MenuIcon />
    </div>
  ) : (
    <div className={classes.Menubtn} onClick={props.clicked}>
      <CloseIcon />
    </div>
  );

export default menuBtn;
