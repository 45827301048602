import React from "react";

import Aux from "../../Aux/Aux";

const tableCell = (props) => (
  <Aux>
    <td>{props.children}</td>
  </Aux>
)

export default tableCell;