import React from "react";

import classes from "./FlexBreak.module.css";

const flexBreak = (props) => {
  return props.direction === "row" ? (
    <div className={classes.FlexBreakRow}>{props.children}</div>
  ) : (
    <div className={classes.FlexBreakColumn}>{props.children}</div>
  );
};

export default flexBreak;
