import React from "react";

import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

import classes from "./MainWeatherReport.module.css";

const mainWeatherReport = (props) => (
  <div className={classes.MainWeatherReport}>
    <div className={classes.locationBox}>
      <LocationOnOutlinedIcon />
      <p className={classes.location}>{props.cityName}</p>
    </div>
    <div className={classes.weatherBox}>
      <p className={classes.mainTemp}>{props.mainTemp}</p>
      <div className={classes.line}></div>
      <div className={classes.catchIndex}>
        <p className={classes.gray}>
          <span className={classes.black}>{props.moonIllumination}</span>
        </p>
        <p className={classes.weather}>
          <span className={classes.black}>{props.pressureCheck} légnyomás</span>
        </p>
      </div>
    </div>
  </div>
);

export default mainWeatherReport;
