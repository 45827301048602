import React from "react";

import classes from "./Btn.module.css";

const btn = (props) => (
  <div className={classes.Btn}>
    <button onClick={props.onClick} children={props.icon}></button>
  </div>
);

export default btn;
