import React from "react";

import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import UmbrellaIcon from "@mui/icons-material/Umbrella";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";

import classes from "./WeeklyTable.module.css";

const weeklyTable = (props) => {
  return (
    <div className={classes.WeeklyTable}>
      <div className={classes.WeeklyTableIcons}>
        <TodayOutlinedIcon />
        <CloudQueueIcon />
        <ThermostatIcon />
        <UmbrellaIcon />
        <AvTimerIcon />
        <CircleOutlinedIcon />
      </div>
      <div className={classes.table}>
        <table>
          <tbody>
            {props.children}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default weeklyTable;
