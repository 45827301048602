import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";

import Layout from "./hoc/Layout/Layout";
import WeatherForecast from "./containers/WeatherForecast/WeatherForecast";
import AboutPage from "./containers/AboutPage/AboutPage";

import { ToastContainer } from "react-toastify";
import CookieConsent from "react-cookie-consent";

import "react-toastify/dist/ReactToastify.css";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Layout>
          <ToastContainer />
          <Route path="/" exact component={WeatherForecast} />
          <Route path="/rolunk" exact component={AboutPage} />
        </Layout>
        <footer>
          <p>© 2023 Lesz kapás? Minden jog fenntartva</p>
          <div className="developed_by">
            <p>Developed by:</p>
            <a
              href="https://www.linkedin.com/in/belabankuti/"
              rel="noreferrer"
              target={"_blank"}
            >
              Bánkuti
            </a>
          </div>
        </footer>
        <CookieConsent
          location="bottom"
          buttonText="Elfogadom"
          cookieName="leszKapasCookie"
          style={{ background: "rgb(0, 105, 255)" }}
          buttonStyle={{
            color: "rgb(8, 26, 74)",
            fontSize: "14px",
            borderRadius: "8px",
            background: "rgb(252, 253, 254)",
            padding: "10px 15px",
            fontFamily: '"Epilogue", sans-serif',
            fontWeight: "600",
          }}
          expires={150}
        >
          <span style={{ fontSize: "14px" }}>
            Ez a weboldal cookie-kat használ a felhasználói élmény fokozása
            érdekében
          </span>
        </CookieConsent>
      </BrowserRouter>
    );
  }
}

export default App;
