import React from "react";

import NavigationItems from "../NavigationItems/Navigationitems";
import Aux from "../../../hoc/Aux/Aux";
import Backdrop from "../../../hoc/UI/Backdrop/Backdrop";

import classes from "./Sidemenu.module.css";

const sideMenu = (props) => {
  let attachedClasses = [classes.SideMenu, classes.Close];

  if (props.open) {
    attachedClasses = [classes.SideMenu, classes.Open];
  }

  return (
    <Aux>
      <Backdrop show={props.open} clicked={props.closed} />
      <div>
        <nav className={attachedClasses.join(" ")}>
          <NavigationItems />
        </nav>
      </div>
    </Aux>
  );
};

export default sideMenu;
